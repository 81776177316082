/* Adiciona fonte Ubuntu */
@font-face {
  font-family: 'Ubuntu';
  src:  url('./assets/fonts/Ubuntu/Ubuntu-Light.ttf') format('truetype');
}
/* Adiciona fonte Segoe-Ui */
@font-face {
  font-family: 'Segoe-Ui';
  src:  url('./assets/fonts/Segoe-Ui/Segoe-UI.ttf') format('truetype');
}
@font-face {
  font-family: 'Nekst';
  src:  url('./assets/fonts/Nekst/nekst-w05-medium.otf') format("opentype");
}

/* Reset do CSS */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* Fim do Reset do CSS */

* {
  font-family: 'Segoe-Ui', sans-serif;
  font-size: 14px;
  box-sizing: border-box;
  /* outline-offset: 2px; */
}

/* html {
  scroll-behavior: smooth;
  font-size: 16px;
} */

/* body {
  width: 100%;
  background-color: var(--white-default);
} */
